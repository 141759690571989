import styled from "styled-components";
import { Section, Container } from "../../sdk/Layout";

export const SectionExtended = styled(Section)`
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
`;

export const InfoItem = styled.div`
    border-radius: 10px;
    background-color: ${(props) => props.theme.white};
    border: solid 1px ${(props) => props.theme.grayLight};
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.02);
    padding: 30px;
    .inner {
        position: relative;
        padding-left: 60px;
        padding-top: 6px;
        .icon {
            width: 35px !important;
            height: 35px !important;
            position: absolute;
            left: 0px;
            top: 0px;
            color: ${(props) => props.theme.primaryDarkMode};
        }
        h3 {
            color: ${(props) => props.theme.primaryDarkMode};
            font-size: 18px;
            margin-bottom: 10px;
        }
        p {
            color: ${(props) => props.theme.gray};
            font-size: 15px;
            line-height: 22px;
        }
    }
    &.contact-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
        color: ${(props) => props.theme.primaryDarkMode};
        transition: all 200ms ease-in-out;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  border: 0.5px solid ${(props) => props.theme.borderColor};
  border-radius: ${(props) => props.theme.radio};
  overflow: hidden;
`;

export const Item = styled.div`
  width: 50%;
  align-items: stretch;
  border: 0.5px solid ${(props) => props.theme.borderColor};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  padding-right: 50px;
  color: ${(props) => props.theme.black};
  margin: 0;
  background: transparent;
  transition: all 200ms ease-in-out;
  h2 {
    font-weight: 800;
    font-size: 26px;
  }

  @media (max-width: 768px) {
    width: 50%;
    gap: 8px;
    padding: 16px;
    padding-right: 30px;
    h2 {
        font-size: 16px;
    }
    p {
        font-size: 14px;
    }
  }
`;

export const InfoItemList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1%;
    ${InfoItem} {
        margin: 1%;
        width: 31.333%;
    }
    @media (max-width: 768px) {
        ${InfoItem} {
            margin: 0.5%;
            width: 49%;
        }
    }
    @media (max-width: 540px) {
        ${InfoItem} {
            width: 99%;
        }
    }
`;

export const Spacer = styled.div`
  height: ${(props) => props.height ?? '10'}px;
  width: 100%;
`;

export const ContainerExtended = styled(Container)`
    display: flex;
    .text {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        padding: 40px 30px;
        background-color: ${(props) => props.theme.primary};
		position: relative;

		svg {
			position: absolute;
			top: 20px;
			right: 20px;
			width: 100px;
			height: 100px;
			color: black;
		}
        h3 {
            font-size: 60px;
            line-height: 1.1;
            color: white;
            font-weight: 900;
            text-wrap: balance;
			z-index: 2;
        }
        a {
            margin-top: 20px;
        }
    }
    .items {
        width: 70%;
    }

    @media (max-width: 1366px) {
        .text {
            h3 {
                font-size: 50px;
            }
        }
    }
    @media (max-width: 1044px) {
        .text {
            h3 {
                font-size: 40px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        .text svg {
            display: none;
        }
        .text {
            background-color: transparent;
            width: 100%;
            padding: 20px;
            h3 {
                color: ${(props) => props.theme.textDefault};
                font-size: 28px;
                text-align: center;
				text-transform: uppercase;
            }
            a {
                display: none;
            }
        }
        .items {
            width: 100%;
        }
    }
`;
