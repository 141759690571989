import React, { useState } from "react";
import { Helmet } from "react-helmet";
import InternalLink from "../InternalLink";
import { Heading, Container } from "../../sdk/Layout";
import { Buttons, Button } from "../../sdk/Button";
import { SectionExtended, InfoItem, InfoItemList, Spacer, Item, Items, ContainerExtended } from "./style";
import useTranslation from "../../../lang/hooks/useTranslation";
import Icons from "../Icons2/";
// import Icons from "../../simpleViews/LandingPrivateLabel/components/PrivateLabelBenefits/Icons";

function ItemComponent({ icon, title, description }) {
    return (
        <Item>
            {/* <Icons icon={icon} className="icon" /> */}
            <h2>{title}</h2>
            <p>{description}</p>
        </Item>
    );
}

const Pagination = (data) => {
    return data.map(({ icon, title, paragraph }) => (
        <ItemComponent key={title} icon={icon} title={title} description={paragraph} />
    ));
};

function Benefits({ config }) {
    const [_t] = useTranslation();
    if (config == null || config.length === 0) return null;

    const [more, setMore] = useState(false);
    const handleMore = () => {
        setMore(!more);
    };

    const initialData = [...config].slice(0, 4);
    const restData = [...config].slice(4);

    return (
        <SectionExtended>
            <ContainerExtended large>
                <div className="items">
                    <Items>
                        {initialData.map(({ icon, title, paragraph }) => (
                            <ItemComponent
                                key={title}
                                icon={icon}
                                title={title}
                                description={paragraph}
                            />
                        ))}

                        {/* {!more ? (
                            <>
                                <Spacer height="20" />
                                <Buttons isCentered>
                                    <Button onClick={handleMore}>
                                        {_t("Ver todos los beneficios")}
                                    </Button>
                                </Buttons>
                            </>
                        ) : (
                            Pagination(restData)
                        )} */}
                    </Items>
                </div>
				<div className="text">
					<Icons icon="ntx-corner-rt" />
					<h3>{_t("Recibe estos beneficios y más")}</h3>
					{/* <InternalLink to="/nosotros">
						<Button>
							{_t("conocenos")}
						</Button>
					</InternalLink> */}
				</div>
            </ContainerExtended>
        </SectionExtended>
    );
}

export default Benefits;
